import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import { paymentMethodsObj, tip, itemWithPrices } from '../../../environment';
import { useStyles } from './styles';
import '../../frontend/scss/cart.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import { cartCrossIcon, cartDotedLine } from '../../../assets/images/newImages';
import { useCart } from '../layout/cartContext'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import '../../frontend/scss/cart.scss';
import PayFully from './payFully.js';
import PayMethodModal from './payMethodModal.js';
import RevolutCheckout from "@revolut/checkout";
import { Alert } from "../../../components";
import QuantityHandler from './quantityHandler.js';

const responsive = {
    0: {
        stagePadding: 2,
        items: 1,
        margin: 2,
    },
    600: {
        items: 1,
        margin: 5,
        stagePadding: 10,
    },
    768: {
        items: 3,
        margin: 5,
        stagePadding: 20,
    },
    992: {
        items: 3,
        margin: 10,
        stagePadding: 10,
    },
    1000: {
        items: 3,
        margin: 20,
        stagePadding: 10,
    },
    1200: {
        items: 3,
        stagePadding: 20,
        margin: 10
    },
    1366: {
        items: 3,
        stagePadding: 0,
        margin: 10
    },
    1600: {
        items: 3,
        stagePadding: 10,
        margin: 7
    },
    1920: {
        items: 3,
        stagePadding: 5,
        margin: 5
    }
}

function CartInner(props) {

    /** Decalartions of all variables and states */
    const { t, i18n } = useTranslation();
    const { cartItemsList, setOpenModalCart, setPaymentSuccess, setTotalAmount,
        restaurantSuccess, currentLanguage, updateOrderItemSuccess, cartType,
        setTotalQuantity, setCartType, closeOpenModalCart, openModalCart } = props;
    // console.log("cartItems list in cartInner",cartItemsList);
    const classes = useStyles();
    const { cartItems, removeItemFromCart, setDivVisible } = useCart();
    const [innerCartItems, setInnerCartItems] = useState([]);
    const [cartItemDeleted, setCartItemDeleted] = useState(false);
    const [cartItemDeletedIndex, setCartItemDeletedIndex] = useState(null);
    const [noOfItems, setNoOfItems] = useState([]);
    const [restaurant, setRestaurant] = useState(null);
    const [pay, setPay] = useState(false);
    const [paymentValue, setPaymentValue] = React.useState(paymentMethodsObj[0].paymentTitle);
    const [totalAmount, setTotal] = useState(0);
    const [tip, setTip] = useState(0);
    const [vat, setVatValue] = useState(0);
    const [paymentGatewaysList, setPaymentGatewaysList] = useState([])
    const [checkoutErrorMessage, setCheckoutErrorMessage] = useState(null);
    const [selectedPaymentGateway, setSelectedPaymentGateway] = useState('MasterCard');
    const [selectedQuantity, setSelectedQuantity] = useState({ quantity: null, index: null, price: null, isActive: false });
    const [calculatedPrice, setCalculatedPrice] = useState(null);
    const [loading, setLoading] = useState(null);
    // console.log("innerCartItems:", innerCartItems)

    const paymentBtn = () => {
        console.log("domain of website", window.location.hostname);

        // setPay(true);
        let etisilat = paymentGatewaysList.find(item => item.title === 'Etisilat');
        let data = {
            domain: window.location.hostname,
            session_id: localStorage.getItem('sessionId'),
            order_id: localStorage.getItem('orderId'),
            payment_gateway_id: paymentValue,
            tip_price: tip
        };
        console.log("Data in paymentBTN: ", data);
        if (paymentValue === etisilat?._id) {
            // data.order_detgails = {
            //     ReturnPath: "http://localhost:3000/order-Placed",
            //     TransactionHint: "CPT:Y;VCC:Y;",
            //     Channel: "Web",
            //     Customer: "Demo Merchant",
            //     OrderName: "payBill"
            // }
            props.createEtisilatOrderForPayment(data);
            setLoading(true);
        }
        else {
            props.createRevoultOrderForPayment(data);
        }
    };

    const closeCart = () => {
        setPay(false);
    }

    const closeCartModal = () => {
        setOpenModalCart(false);
        setDivVisible(true);
    }

    const payBtnClick = () => {
        setOpenModalCart(false);
        setPaymentSuccess(true);
    }
    /** Old Code which is used to set the quantity or  removal of items right now this function is not using */
    // const handleNoOfItem = (type, index, value) => {
    //     console.log("index", index, "type", type, "value", value);
    //     const newNoOfItems = [...noOfItems]; // Create a copy of the state array
    //     console.log("newNoOfItems", newNoOfItems);
    //     if (type === 1 && value > 1) {
    //         newNoOfItems[index] = value - 1; // Decrease by 1 the value at the specified index
    //     }
    //     else if (type === 1 && value === 1) {
    //         console.log("in else if of delete item");
    //         // Delete the item from cart
    //         setCartItemDeleted(true);
    //         setCartItemDeletedIndex(index);
    //         const updatedItem = innerCartItems[index];
    //         const orderData = {
    //             sessionId: localStorage.getItem("sessionId"),
    //             orderId: localStorage.getItem("orderId"),
    //             cartId: updatedItem._id,
    //             user_id: '65fa8d79b94da3c8da28b50f',
    //             restaurant_branch_id: restaurant?.branches[0]?._id,
    //             cartDetails: {
    //                 quantity: updatedItem.quantity,
    //                 price: updatedItem.product.price * updatedItem.quantity,
    //                 category_id: updatedItem.product.category_id,
    //                 product_id: updatedItem.product._id,
    //                 status: 0
    //             }
    //         };
    //         // localStorage.setItem("itemDeleted", JSON.stringify(orderData));
    //         // props.updateOrderItem(orderData);
    //     }
    //     else if (type === 2)
    //         newNoOfItems[index] = value + 1; // Increase by 1 the value at the specified index
    //     setNoOfItems(newNoOfItems); // Update the state
    // };

    useEffect(() => {
        if (restaurantSuccess?.data)
            setRestaurant(restaurantSuccess?.data);
        // productCategory();
    }, [restaurantSuccess]);

    useEffect(() => {
        if (restaurant && props.paymentGateways?.data?.length)
            setPaymentGatewaysList(props.paymentGateways?.data);
        // setPaymentGatewaysList(props.paymentGateways?.data.filter(paymentGateway => restaurant.branches[0].payment_gateway_ids?.includes(paymentGateway._id)))
    }, [restaurant, props.paymentGateways])

    useEffect(() => {
        console.log("item quantity is changed")
        if (cartItemsList?.data) {
            setInnerCartItems(cartItemsList.data)
        }
    }, [cartItemsList]);

    useEffect(() => {
        // This useEffect is to remove item from localStorage when quantity is 0 in db cart
        if (updateOrderItemSuccess && cartItemDeleted && cartItemDeletedIndex !== null) {
            removeItemFromCart(cartItemDeletedIndex);
            setCartItemDeleted(false);
            setCartItemDeletedIndex(null);
        }
    }, [updateOrderItemSuccess, cartItemDeleted, cartItemDeletedIndex])

    useEffect(() => {
        props.getPaymentGateways();
    }, [])

    // useEffect(() => {
    //     if (innerCartItems) {
    //         console.log("useEffect OF noOfItems " + noOfItems);
    //         noOfItems.map((item, index) => {
    //             if (innerCartItems[index].quantity) { //!== item
    //                 console.log("quantity changed at index " + index);
    //                 innerCartItems[index].quantity = item;
    //                 const updatedItem = innerCartItems[index];
    //                 const orderData = {
    //                     sessionId: localStorage.getItem("sessionId"),
    //                     orderId: localStorage.getItem("orderId"),
    //                     cartId: updatedItem._id,
    //                     user_id: '65fa8d79b94da3c8da28b50f',
    //                     restaurant_branch_id: restaurant?.branches[0]?._id,
    //                     cartDetails: {
    //                         quantity: updatedItem.quantity,
    //                         price: updatedItem.product.price * updatedItem.quantity,
    //                         category_id: updatedItem.product.category_id,
    //                         product_id: updatedItem.product._id,
    //                     }
    //                 };
    //                 console.log("orderData", orderData);
    //                 // props.updateOrderItem(orderData);
    //             }
    //         })
    //     }
    // }, [noOfItems]);

    useEffect(() => {
        const orderId = localStorage.getItem('orderId');
        if (orderId && updateOrderItemSuccess)
            props.getCartItems({ order_id: orderId, language_id: currentLanguage._id });
    }, [updateOrderItemSuccess])

    useEffect(() => {
        setNoOfItems(innerCartItems.map((cartItem) => cartItem.quantity));
    }, [innerCartItems])

    useEffect(() => {
        const baseTotal = innerCartItems.filter(item => item.quantity > 0 && item.status !== 0)
            .reduce((acc, currentItem, itemIndex) => { // filter method is used to exclude the items which quantity is 0.
                // Calculate the item price based on quantity
                const itemBasePrice = currentItem.product.price * noOfItems[itemIndex];
                // Calculate total addons price if addons exist
                const addonsTotal = Array.isArray(currentItem.addons)
                    ? currentItem.addons.reduce((addonAcc, addon) => addonAcc + (addon.price || 0), 0)
                    : 0;
                // Calculate total for the item including addons
                const itemTotal = itemBasePrice + addonsTotal;
                return acc + itemTotal;
            }, 0);
        // Retrieve VAT percentage from local storage and apply it
        const vatPercentage = localStorage.getItem('vat');
        const vatAmount = baseTotal * (vatPercentage / 100);
        const service_fee = parseInt(localStorage.getItem('service_fee'))
        setVatValue(parseFloat(vatAmount).toFixed(2));
        const totalAmount = baseTotal + vatAmount + service_fee;       // setTotal(baseTotal + vatAmount + service_fee); //setting total Amount
        setTotal(parseFloat(totalAmount).toFixed(2));
        setTotalAmount(totalAmount);
        // Calculate the total quantity
        const total = innerCartItems.reduce((acc, item) => acc + item.quantity, 0);
        // Update the totalQuantity in the parent component
        setTotalQuantity(total);
        // setInnerCartItems(cartItems);
    }, [innerCartItems, noOfItems, setTotalAmount]);

    useEffect(() => {
        if (props?.createEtisilatOrderForPaymentSuccess) {
            console.log("props.createEtisilatOrderFor", props?.createEtisilatOrderForPaymentSuccess);
            const paymentPortalUrl = props?.createEtisilatOrderForPaymentSuccess.data?.Transaction?.PaymentPortal;
            const transactionId = props?.createEtisilatOrderForPaymentSuccess.data?.Transaction?.TransactionID;
            window.location.href = `${paymentPortalUrl}?TransactionID=${transactionId}`;
            props.messageHandler();
        }
        else if (props?.createEtisilatOrderForPaymentError) {
            setTimeout(() => {
                props.messageHandler();
            }, 3000)
        }
    }, [props?.createEtisilatOrderForPaymentSuccess, props?.createEtisilatOrderForPaymentError]);

    useEffect(() => {
        if (props?.createRevoultOrderForPaymentSuccess) {
            const orderToken = props?.createRevoultOrderForPaymentSuccess.data?.token;
            const orderId = props?.createRevoultOrderForPaymentSuccess.data?.id;
            paymentFunction(orderToken, orderId);
            props.messageHandler();
        }
        else if (props?.createRevoultOrderForPaymentError) {
            setTimeout(() => {
                props.messageHandler();
            }, 3000)
        }
    }, [props?.createRevoultOrderForPaymentSuccess, props?.createRevoultOrderForPaymentError]);

    const paymentFunction = async (orderToken, order_id) => {
        const { payWithPopup } = await RevolutCheckout(orderToken, 'sandbox')
        // Initialisation code will go here

        const popUp = payWithPopup({
            onSuccess() {
                props.retrievePaymentDetails({ order_id })
                // Do something to handle successful payments
            },
            onError(error) {
                // Do something to handle successful payments
                setCheckoutErrorMessage(error.message);
                setTimeout(() => {
                    setCheckoutErrorMessage(null);
                }, 3000)
            }
        })
    }

    // const handleCreateOrder = () => {
    //     const orderData = {
    //         sessionId: '',
    //         split_type: 1,
    //         type: 1,
    //         user_id: '65fa8d79b94da3c8da28b50f',
    //         quantity: parseInt(noOfItems),
    //         price: total.toString(),
    //         category_id: innerCartItems[0].category_id,
    //         product_id: innerCartItems[0]._id,
    //         restaurant_branch_id:"660d01683160b95fd00283c7",
    //         personalDetails: {
    //             first_name: "Ahmed",
    //             last_name: "ali",
    //             email: "demo@gmail.com",
    //             mobile_no: "+923335976737",
    //         },

    //     };
    //     props.postOrder(orderData);

    // };

    const handleCreateOrder = () => {
        setCartType(true);
        // console.log("payfully btn clicked");
        // setPaymentMethodCart(true);
        // history.push({ pathname: './cart-Details', state: { currency: restaurant?.branches[0].currency, cartItems: innerCartItems } });
        // innerCartItems.forEach((item) => {
        //     const orderData = {
        //         sessionId: '',
        //         split_type: 1,
        //         type: 1,
        //         user_id: '65fa8d79b94da3c8da28b50f',
        //         quantity: parseInt(noOfItems), 
        //         price: total.toString(),
        //         category_id: item.category_id,
        //         restaurant_branch_id: "660d01683160b95fd00283c7",
        //         product_id: item._id,
        //         personalDetails: {
        //             first_name: "Muhammad",
        //             last_name: "Fahad",
        //             email: "abc@example.com",
        //             mobile_no: "+923335976737"
        //         }
        //     };
        //     props.postOrder(orderData);
        // });
    };

    const handlePaymentChange = (event) => {
        setPaymentValue(event.target.value);
    };

    const viewportHeight = window.innerHeight; //get the hight of visisble window // console.log("viewportHeight", viewportHeight);
    const modalhight = viewportHeight - 44; //40px minus 44 is used to minus the portion having logo in modal // console.log("modal hight", modalhight);
    const modalHightDivision = (modalhight / 2) - 100;// console.log("modal hight division", modalHightDivision);

    // Determine the classes based on conditions
    const getClassName = () => {
        let classNames = 'payable-amount'; // Default class
        if (currentLanguage?.left_to_right === 0) {
            classNames += ' payable-amount-rtl'; // Add RTL class if needed
        }
        if (viewportHeight < 760) {
            classNames += ' payable-amount-small-screen'; // Add small screen class if needed
        }
        if (viewportHeight < 731) {
            classNames += 'payable-amount-very-small-screen' // Add very small screen 
        }
        return classNames;
    };

    const handleQuantity = (quantity, index, price) => {
        setSelectedQuantity((prevState) => {
            // Toggle if the same item is clicked, else set a new item
            const isSameItem = prevState.index === index;
            console.log("isSameItem", isSameItem);
            return {
                quantity, index, price,
                isActive: isSameItem ? !prevState.isActive : true,
            };
        });
    };

    // In this function handleQuantityChange we are handling the quantity change of each item
    // const handleQuantityChange = (updatedQuantity, index) => {
    //     if (updatedQuantity === 0) {
    //         // Update the state and ensure the correct values are used
    //         setInnerCartItems((prevItems) => {
    //             const updatedItems = prevItems.map((item, i) =>
    //                 i === index
    //                     ? {
    //                         ...item,
    //                         status: 0,
    //                         quantity: updatedQuantity,
    //                     }
    //                     : item
    //             );
    //             const itemDeleted = prevItems[index]; //updatedItems[index]; // // Use previous state to get the current item
    //             // Remove the item from localStorage
    //             const cart = JSON.parse(localStorage.getItem("cart")) || []; // Get cart array
    //             const updatedCart = cart.filter((cartItem) => cartItem._id !== itemDeleted._id); // Remove by _id
    //             localStorage.setItem("cart", JSON.stringify(updatedCart)); // Save updated cart back to localStorage
    //             console.log("Item deleted", itemDeleted);
    //             console.log("Item deleted price type", typeof (itemDeleted?.price));
    //             const orderData = {
    //                 sessionId: localStorage.getItem("sessionId"),
    //                 orderId: localStorage.getItem("orderId"),
    //                 cartId: itemDeleted._id,
    //                 user_id: "65fa8d79b94da3c8da28b50f",
    //                 restaurant_branch_id: restaurant?.branches[0]?._id,
    //                 cartDetails: {
    //                     quantity: itemDeleted.quantity, // Still 0 at this point
    //                     price: itemDeleted?.price,//itemDeleted.product.price * itemDeleted.quantity,
    //                     category_id: itemDeleted.product.category_id,
    //                     product_id: itemDeleted.product._id,
    //                     status: 0,
    //                 },
    //             };
    //             console.log("Order Data on deletion of item", orderData);
    //             // Call the API
    //             props.updateOrderItem(orderData);
    //             return updatedItems;
    //         });

    //     } else {
    //         // Update the state and ensure the correct values are used
    //         setInnerCartItems((prevItems) => {
    //             const updatedItems = prevItems.map((item, i) =>
    //                 i === index
    //                     ? {
    //                         ...item,
    //                         quantity: updatedQuantity,
    //                         price: updatedQuantity * (item.price / item.quantity), // Update the price dynamically
    //                     }
    //                     : item
    //             );
    //             const itemUpdated = updatedItems[index]; // Access the updated state here    
    //             const orderData = {
    //                 sessionId: localStorage.getItem("sessionId"),
    //                 orderId: localStorage.getItem("orderId"),
    //                 cartId: itemUpdated._id,
    //                 user_id: "65fa8d79b94da3c8da28b50f",
    //                 restaurant_branch_id: restaurant?.branches[0]?._id,
    //                 cartDetails: {
    //                     quantity: itemUpdated.quantity,
    //                     price: itemUpdated?.price,
    //                     category_id: itemUpdated.product.category_id,
    //                     product_id: itemUpdated.product._id,
    //                     status: 1,
    //                 },
    //             };

    //             console.log("orderData in the quantity change", orderData);
    //             // Call the API
    //             props.updateOrderItem(orderData);
    //             return updatedItems;
    //         });

    //         // Update the selectedQuantity state
    //         setSelectedQuantity((prevState) => ({
    //             ...prevState,
    //             quantity: updatedQuantity,
    //             isActive: false, // Automatically close the QuantityHandler
    //         }));
    //     }
    // };
    const handleQuantityChange = (updatedQuantity, index) => {
        if (updatedQuantity === 0) {
            // Get the current cart
            const cart = JSON.parse(localStorage.getItem("cart")) || [];

            setInnerCartItems((prevItems) => {
                const updatedItems = prevItems.filter((_, i) => i !== index); // Remove item by index
                return updatedItems;
            });

            // Get the deleted item (before state update)
            const itemDeleted = innerCartItems[index];
            if (!itemDeleted) return; // Prevent errors if item is undefined

            // Remove from localStorage
            const updatedCart = cart.filter(cartItem => cartItem._id !== itemDeleted._id);
            localStorage.setItem("cart", JSON.stringify(updatedCart));

            // Prepare API data
            const orderData = {
                sessionId: localStorage.getItem("sessionId"),
                orderId: localStorage.getItem("orderId"),
                cartId: itemDeleted._id,
                user_id: "65fa8d79b94da3c8da28b50f",
                restaurant_branch_id: restaurant?.branches[0]?._id,
                cartDetails: {
                    quantity: 0, // Ensure quantity is zero
                    price: itemDeleted?.price || 0, // Prevent null price
                    category_id: itemDeleted?.product?.category_id,
                    product_id: itemDeleted?.product?._id,
                    status: 0,
                },
            };

            console.log("Order Data on deletion:", orderData);

            // ✅ Call API only ONCE
            props.updateOrderItem(orderData);

        } else {
            setInnerCartItems((prevItems) => {
                const updatedItems = prevItems.map((item, i) =>
                    i === index
                        ? {
                            ...item,
                            quantity: updatedQuantity,
                            price: updatedQuantity * (item.price / item.quantity),
                        }
                        : item
                );

                return updatedItems;
            });

            // Fetch the updated item after state update
            const itemUpdated = innerCartItems[index];
            if (!itemUpdated) return;

            // Prepare API data
            const orderData = {
                sessionId: localStorage.getItem("sessionId"),
                orderId: localStorage.getItem("orderId"),
                cartId: itemUpdated._id,
                user_id: "65fa8d79b94da3c8da28b50f",
                restaurant_branch_id: restaurant?.branches[0]?._id,
                cartDetails: {
                    quantity: itemUpdated.quantity,
                    price: itemUpdated?.price || 0, // Prevent null price
                    category_id: itemUpdated?.product?.category_id,
                    product_id: itemUpdated?.product?._id,
                    status: 1,
                },
            };

            console.log("Order Data on quantity change:", orderData);

            // ✅ Call API only ONCE
            props.updateOrderItem(orderData);

            // Update the selectedQuantity state
            setSelectedQuantity((prevState) => ({
                ...prevState,
                quantity: updatedQuantity,
                isActive: false, // Automatically close the QuantityHandler
            }));
        }
    };


    return (
        <div className='cart-sub-div' style={{ height: modalhight, }}>{/*backgroundColor:'blue'*/}
            {((props.error || props.createRevoultOrderForPaymentError || props.createEtisilatOrderForPaymentError || checkoutErrorMessage) && !props.loading) &&
                <Alert type={'error'} message={props.error?.message || props.createRevoultOrderForPaymentError || props.createEtisilatOrderForPaymentError || checkoutErrorMessage} />
            }

            {!cartType && //props.currentLanguage?.left_to_right === 1 &&
                <>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className='cart-logo'>
                            <img src={restaurant?.logo} className="cart-logo-image" />
                        </div>
                    </div>

                    <div className='cart-main-div-head '>

                        <div className={currentLanguage?.left_to_right === 0 ? 'cart-head-cross-rtl' : 'cart-head-cross'} >
                            <h2 className={currentLanguage?.left_to_right === 0 ? 'table-heading table-heading-rtl' : 'table-heading'}>
                                {t('table')}: 1
                            </h2>
                            <span className='cross-svg-style'>
                                <img onClick={closeCartModal} src={cartCrossIcon} />
                            </span>
                        </div>

                        <Scrollbars className='bill-item' style={{ height: modalHightDivision }}  >
                            {innerCartItems.filter(item => item.status !== 0).map((item, index) => (
                                <div className={currentLanguage?.left_to_right === 0 ? 'main-bill-div-rtl' : 'main-bill-div'} key={item.product.title}>
                                    <div className='sub-bill-div'>
                                        <div onClick={() => handleQuantity(item.quantity, index, item.price,)}>
                                            <span className={currentLanguage?.left_to_right === 0 ? 'qty-style-rtl' : 'qty-style'}>
                                                {/* {it em.quantity} x */}
                                                {selectedQuantity.index === index ? selectedQuantity.quantity : item.quantity}x
                                            </span>
                                        </div>
                                        {selectedQuantity !== null && selectedQuantity.index === index ? (
                                            <QuantityHandler
                                                quantity={selectedQuantity.quantity}
                                                onQuantityChange={(updatedQuantity) => handleQuantityChange(updatedQuantity, index)}
                                            />
                                        ) : (
                                            <>
                                                <div className='bill-heading-div'>
                                                    <h3 className={currentLanguage?.left_to_right === 0 ? 'bill-heading bill-heading-rtl' : 'bill-heading'}>
                                                        {item?.product?.title}
                                                    </h3>
                                                    {item?.addons?.length != 0 ?
                                                        <>
                                                            <p className={currentLanguage?.left_to_right === 0 ? 'bill-parah bill-parah-rtl' : 'bill-parah'}>
                                                                {restaurantSuccess?.data?.branches[0].currency}     {/* SAR */}
                                                                {item?.product?.price}                  {/* {item?.price} */}
                                                            </p>
                                                            <p className={currentLanguage?.left_to_right === 0 ? 'bill-parah bill-parah-rtl' : 'bill-parah'}>
                                                                {item?.addons?.map((element, index) => (
                                                                    <span>
                                                                        <span className='Add-On'>Add On:</span>
                                                                        <span className='Add-On-Item'> {element?.title} </span>
                                                                        {/* {restaurantSuccess?.data?.branches[0]?.currency}    SAR */}
                                                                        {/* {element?.price} */}
                                                                    </span>
                                                                ))}
                                                            </p>
                                                        </> :
                                                        <p className={currentLanguage?.left_to_right === 0 ? 'bill-parah bill-parah-rtl' : 'bill-parah'}>
                                                            {restaurantSuccess?.data?.branches[0].currency}     {/* SAR */}
                                                            {item?.product?.price}                  {/* {item?.price} */}
                                                        </p>
                                                    }
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className='item-total-price-div'>
                                        <span className={currentLanguage?.left_to_right === 0 ? 'item-total-price item-total-price-rtl' : 'item-total-price'} >
                                            {restaurantSuccess?.data?.branches[0].currency}.   {/* SAR */}
                                            <span>
                                                {item?.addons?.length !== 0 ?
                                                    parseFloat(item.price).toFixed(2)         // parseFloat(item?.price + item?.addons?.reduce((acc, addon) => acc + addon.price, 0)).toFixed(2)
                                                    :
                                                    <>
                                                        {
                                                            item?.addons?.length !== 0 ?
                                                                parseFloat(item?.price + item?.addons?.reduce((acc, addon) => acc + addon.price, 0)).toFixed(2)
                                                                : selectedQuantity.index === index
                                                                    ? parseFloat(selectedQuantity.quantity * item.product.price).toFixed(2) //
                                                                    : parseFloat(item?.product?.price * item.quantity).toFixed(2) // item.quantity *
                                                        }
                                                        {/* {item.addons.length !== 0
                                                            ? item.price + item.addons.reduce((acc, addon) => acc + addon.price, 0)
                                                            : selectedQuantity.index === index      // && selectedQuantity.isActive
                                                                ? calculatedPrice
                                                                : item.quantity * item.price} */}
                                                        {/* {selectedQuantity.index === index
                                                            ? calculatedPrice
                                                            : item.quantity * item?.price} */}
                                                    </>
                                                }
                                            </span>
                                        </span>
                                        <span className={currentLanguage?.left_to_right === 0 ? 'Add-on-price Add-on-price-rtl' : 'Add-on-price'}>
                                            {item?.addons?.length !== 0 ? (
                                                <>
                                                    {restaurantSuccess?.data?.branches[0].currency}.
                                                    <span>
                                                        {parseFloat(item?.addons?.reduce((acc, addon) => acc + addon.price, 0)).toFixed(2)}
                                                    </span>
                                                </>
                                            ) : ''}

                                            {/* {
                                                    item?.addons?.length !== 0 ?
                                                        `${restaurantSuccess?.data?.branches[0].currency}.`  <span>
                                                        `${parseFloat(item?.addons?.reduce((acc, addon) => acc + addon.price, 0)).toFixed(2)}`</span> :
                                                        ''
                                                } */}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </Scrollbars>

                        <div className='combine-div-for-payment' style={{ marginTop: '-5px' }}>
                            <div className='cart-doted-line-div'>
                                <img className='cart-doted-line-div-img' style={{ width: '100%' }} src={cartDotedLine} />
                            </div>

                            <div>
                                <div className={currentLanguage?.left_to_right === 0 ? 'cart-payment-div-rtl' : 'cart-payment-div'}>
                                    <div className={viewportHeight < 760 ? 'cart-payment-sub-div1-small-screen' : 'cart-payment-sub-div1'}> {/**className= 'cart-payment-sub-div1' */}
                                        <span className={currentLanguage?.left_to_right === 0 ? 'cart-pay-vat cart-pay-vat-rtl' : 'cart-pay-vat'}>
                                            {t('vat')}({localStorage.getItem("vat")}%)
                                        </span>
                                        <span className='currency'>
                                            {restaurantSuccess?.data?.branches[0].currency} {/* SAR. */}
                                            <span className='sar-price'>
                                                {vat}
                                            </span>
                                        </span>
                                    </div>
                                    <div className={viewportHeight < 760 ? 'cart-payment-sub-div2-small-screen' : 'cart-payment-sub-div2'} >  {/* className='cart-payment-sub-div2'*/}
                                        <span className={currentLanguage?.left_to_right === 0 ? 'riapay-price riapay-price-rtl' : 'riapay-price'}> {t('riaPayFree')}</span>
                                        <span className='riapay-sar'>
                                            {restaurantSuccess?.data?.branches[0].currency}.  {/* SAR. */}
                                            <span className='riapay-price'>{localStorage.getItem("service_fee")}</span>
                                        </span>
                                    </div>
                                </div>

                                {/* <div className={(currentLanguage?.left_to_right === 0 ? 'payable-amount-rtl payable-amount':'payable-amount') && (viewportHeight < 700 ? 'payable-amount-small-screen':'')}> */}

                                <div className={getClassName()}>
                                    <div style={{ paddingLeft: '25px' }}>
                                        <h3 className={currentLanguage?.left_to_right === 0 ? 'paya-head paya-head-rtl' : 'paya-head'}> {t('payableAmt')}</h3>
                                        <p className='paya-parah'>{t('taxInclusive')}</p>
                                    </div>
                                    <div>
                                        <span className={currentLanguage?.left_to_right === 0 ? 'paya-price paya-price-rtl' : 'paya-price'}>
                                            {restaurantSuccess?.data?.branches[0].currency} {/* SAR */}
                                            {totalAmount}
                                        </span>
                                    </div>
                                </div>


                                <div className="payment-btn">
                                    <button className={currentLanguage?.left_to_right === 0 ? 'payment-button1-CartInner payment-button1-CartInner-rtl' : 'payment-button1-CartInner'}>
                                        {t('splitBill')}
                                    </button>
                                    <button onClick={handleCreateOrder} className={currentLanguage?.left_to_right === 0 ? 'payment-button2-CartInner payment-button2-CartInner-rtl' : 'payment-button2-CartInner'}>
                                        {t('payFully')}
                                    </button>
                                    {/* <button onClick={handleCreateOrder} style={{ cursor: 'pointer' }}>{t('proceedToPayment')}</button> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            }
            {/*payFully compnent*/}
            {cartType &&  //props.currentLanguage?.left_to_right === 1
                <>
                    <PayFully
                        tip={tip}
                        setTip={setTip}
                        restaurant={restaurant}
                        paymentValue={paymentValue}
                        handlePaymentChange={handlePaymentChange}
                        closeCartModal={closeCartModal}
                        paymentMethodsObj={paymentGatewaysList}
                        paymentBtn={paymentBtn}
                        classes={classes}
                        currentLanguage={currentLanguage}
                        totalAmount={totalAmount}
                        restaurantSuccess={restaurantSuccess}
                        loading={props?.loading} //restaurant reducer variables loading
                        etisilatLoading={props?.etisilatLoading}
                        setCartType={setCartType}
                    />

                    <div className='cart-main-div-head'>
                        {pay &&
                            <>
                                <PayMethodModal
                                    classes={classes}
                                    pay={pay}
                                    closeCart={closeCart}
                                    payBtnClick={payBtnClick}
                                    currentLanguage={currentLanguage}
                                />
                            </>
                        }
                    </div>
                </>
            }
        </div>
    )
}

// what is needed at start
const mapStateToProps = ({ restaurantReducer, languageReducer, homeReducer, paymentReducer }) => {
    const { cartItemsList } = homeReducer;
    const { currentLanguage } = languageReducer;
    const { paymentGateways } = paymentReducer;
    const { loading, error, success, createOrder, restaurantSuccess, updateOrderItemSuccess, updateOrderItemLoading, createEtisilatOrderForPaymentSuccess, createEtisilatOrderForPaymentError, createRevoultOrderForPaymentSuccess, createRevoultOrderForPaymentError, etisilatLoading } = restaurantReducer
    return { loading, error, success, createOrder, restaurantSuccess, updateOrderItemSuccess, cartItemsList, currentLanguage, paymentGateways, createEtisilatOrderForPaymentSuccess, createEtisilatOrderForPaymentError, createRevoultOrderForPaymentSuccess, createRevoultOrderForPaymentError, etisilatLoading };
};
//which actions our function can dispatch
const mapDispatchToProps = (dispatch) => {
    return {
        getCartItems: (data) => dispatch(actions.getCartItemsStart(data)),
        updateOrderItem: (data) => dispatch(actions.updateOrderItemStart(data)),
        getPaymentGateways: () => dispatch(actions.getPaymentGateways()),
        createEtisilatOrderForPayment: (data) => dispatch(actions.createEtisilatOrderForPaymentStart(data)),
        createRevoultOrderForPayment: (data) => dispatch(actions.createRevoultOrderForPaymentStart(data)),
        retrievePaymentDetails: (data) => dispatch(actions.getOrderPaymentStart(data)),
        // postOrder: (data) => dispatch(actions.getOrderStart(data)),
        messageHandler: () => dispatch(actions.messageHandler()),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(CartInner);