import React, { useState, useEffect,useCallback, useImperativeHandle, useContext } from 'react';
import { useStyles } from './styles';
import { cart, foodCart } from '../../../assets/images/img'
import { Slide } from 'react-reveal';
import { selectedDishes, RelatedItems, selectedDishesArray, relatedItems } from '../../../environment';
import '../../frontend/scss/cart.scss';
import CartInner from './cartInner';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import theme from '../scss/ThemeStyles.scss';
import { ThemeContext } from "../../../ThemeContext";
import { useCart } from '../layout/cartContext'
import { cross } from '../../../assets/images/images';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import * as actions from "../../../store/actions";


function Cart(props) {
    const { setOpenModalCart, currentLanguage, setPaymentSuccess, setTotalQuantity,
            setTotalPrice, 
            closeOpenModalCart, totalPrice,totalQuantity, } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const { cartItems, addItemToCart,  } = useCart(); // Step 3: Consume the context
    const [cartType,setCartType] = useState(false);
    const [billModal,setBillModal] = useState(true);
    const [totalAmount, setTotalAmount] = useState(0); // Parent state to store the total amount

    const closeMainCart = () => {
        setBillModal(false);
    }
    
    // useEffect for the layout of the modal window. 
    useEffect(() => {
        const isModalOpen = localStorage.getItem('cartModal');
        console.log("isModalOpen", isModalOpen);
        // if (isModalOpen === true) {
        function handleClickOutside(event) {
            const clickedInside = event.target.closest('.cart-div');
            if (clickedInside) {
                setOpenModalCart(true);
                // localStorage.setItem('cartModal',false);
            }
            // }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, []);

    useEffect(() => {
        if (cartItems && currentLanguage)
            fetchCartItems();
    }, [currentLanguage, cartItems])

    const fetchCartItems = () => {
        const orderId = localStorage.getItem('orderId');
        if (orderId)
            props.getCartItems({ order_id: orderId, language_id: currentLanguage._id })
    }

    const history = useHistory();
    const handleProceedToPaymentBtn = () => {
        history.push('./cart-Details')
    };
    
    //code for toggle button
    const [alignment, setAlignment] = React.useState('web');
    const { themeValue } = useContext(ThemeContext);
    
    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };
    
    const handleCloseModal = () => {
        setOpenModalCart(false)
        //  localStorage.setItem('cartModal',false);
    }

    const isMobile = useMediaQuery('(max-width:960px)');

    return (
        <>
            <Slide up> {/* Slide right */}
                    <div className='cart-div' data-theme={themeValue}>
                        <CartInner
                            cartItemsList={cartItems}
                            setOpenModalCart={setOpenModalCart}
                            setCartType={setCartType}
                            cartType={cartType}
                            setPaymentSuccess={setPaymentSuccess}
                            setTotalAmount={setTotalPrice} // Pass a callback to update totalAmount in parent
                            setTotalQuantity={setTotalQuantity}
                            // relatedItems={relatedItems}
                        />
                    </div>
            </Slide>
        </>
    )
}
// what is needed at start
const mapStateToProps = ({ languageReducer }) => {
    const { currentLanguage } = languageReducer;
    return { currentLanguage };
};
//which actions our function can dispatch
const mapDispatchToProps = (dispatch) => {
    return {
        getCartItems: (data) => dispatch(actions.getCartItemsStart(data)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Cart);